'use client'

import React, { useCallback, useEffect, useState } from 'react'
import Link from 'next/link'
import styles from './LoginForm.module.scss'
import GoogleSignIn from './GoogleSignIn'
import useAuth, { AuthErrors } from '@crystal-eyes/hooks/useAuth'
import Button from '@crystal-eyes/premeeting/components/elements/Button/Button'
import { match } from 'ts-pattern'

export default function LoginForm() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [twoFactorCode, setTwoFactorCode] = useState('')
  const { authed, success, loading, error, doLogin } = useAuth()

  const onGoogleSignIn = useCallback(
    (googleToken: string, email: string) => {
      doLogin(email, '', { gToken: googleToken })
    },
    [doLogin],
  )
  const onSubmit = useCallback(
    (evt: React.FormEvent) => {
      evt.preventDefault()
      doLogin(email, password, { twoFactorCode })
    },
    [email, password, twoFactorCode, doLogin],
  )
  useEffect(() => {
    if (authed) window.location.href = '/logged_in'
  }, [authed])

  const state =
    error !== AuthErrors.NeedTwoFactor ? 'credentials' : 'two_factor'

  const errorMessage = match(error)
    .with(
      AuthErrors.Invalid,
      () => 'Login failed. Please check your email and password.',
    )
    .with(AuthErrors.Unknown, () => 'Server Error. Please try again shortly')
    .with(AuthErrors.NeedTwoFactor, () => '')
    .otherwise(() => '')

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      {state === 'credentials' && (
        <>
          <label htmlFor="login-email">Email</label>
          <input
            type="email"
            name="email"
            id="login-email"
            value={email}
            onChange={evt => setEmail(evt.target.value)}
            required
          />

          <label htmlFor="login-password">Password</label>
          <input
            type="password"
            name="password"
            id="login-password"
            value={password}
            onChange={evt => setPassword(evt.target.value)}
            required
          />

          <div className={styles.forgotPassword}>
            <Link prefetch={false} href="/app/password">
              Forgot Password
            </Link>
          </div>
        </>
      )}

      {state === 'two_factor' && (
        <>
          <div className={styles.twoFactorMessage}>
            Check your authenticator mobile app for your authentication code
          </div>
          <label htmlFor="login-two-factor">Two-Factor Auth Code</label>
          <input
            type="number"
            name="two-factor"
            id="login-two-factor"
            value={twoFactorCode}
            onChange={evt => setTwoFactorCode(evt.target.value)}
            placeholder="042727"
            required
          />
        </>
      )}

      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}

      <div className="tw-self-center">
        <Button
          theme="primary"
          type="submit"
          disabled={success || loading || !email || !password}
        >
          {loading || success ? 'Submitting...' : 'Sign In'}
        </Button>
      </div>
      {state === 'credentials' && (
        <>
          <div className={styles.googleLoginCta}>
            <b>OR</b>

            <div className="sso-buttons">
              <GoogleSignIn onSignIn={onGoogleSignIn} />

              <Link
                prefetch={false}
                href="/okta"
                className="sign-in-okta-button"
              >
                Sign in with SSO
              </Link>
            </div>
          </div>
        </>
      )}

      <div className={styles.registerCta}>
        <span>Don't have an account?</span>
        <Link prefetch={false} href="/app/register">
          Sign up
        </Link>
      </div>
    </form>
  )
}
