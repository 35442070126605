export default {
  apiUrl: 'https://api.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.crystalknows.com',
  appUrl: 'https://www.crystalknows.com',
  devMode: false,
  hubURL: 'https://hub.crystalknows.com',
  marketingUrl: 'https://www.crystalknows.com',
  pk_live: 'pk_live_OZGp67qDSVKzl0S91k234Izk',
  stripe_price_id: 'price_0QEwj3nIS1nxHN4VHs4elXrs',
  rudderStackWriteKey: '1lkCRX5aDsbofHEg8ET8Kyz4vuc',
  useDevtoolsExtension: false,
  useFullStory: true,
  useHubspotAnalytics: true,
  authTokenName: 'CRYSTAL_AUTH_TOKEN',
  sessionTokenName: 'CRYSTAL_SESSION_TOKEN',
  pageTokenName: 'CRYSTAL_PAGE_TOKEN',
}
