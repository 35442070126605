export default {
  apiUrl: 'https://api.integration.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.staging.crystalknows.com',
  appUrl: 'https://integration.crystalknows.com',
  devMode: false,
  hubURL: 'https://hub.integration.crystalknows.com',
  marketingUrl: 'https://integration.crystalknows.com',
  pk_live: 'pk_test_2DwpK6wrrckZ87ZjEfMsyabr',
  rudderStackWriteKey: '1kU6UMcvdKczMrftJNVU8VvT4oZ',
  useDevtoolsExtension: true,
  useFullStory: false,
  useHubspotAnalytics: false,
  authTokenName: 'CRYSTAL_INTEG_AUTH',
  sessionTokenName: 'CRYSTAL_INTEG_SESS',
  pageTokenName: 'CRYSTAL_INTEG_PAGE',
}
