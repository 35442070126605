export default {
  apiUrl: 'https://test.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.staging.crystalknows.com',
  appUrl: 'https://www.crystalknows.com',
  devMode: false,
  hubURL: 'https://hub.crystalknows.com',
  marketingUrl: 'https://www.crystalknows.com',
  pk_live: '',
  rudderStackWriteKey: '',
  useDevtoolsExtension: false,
  useFullStory: true,
  useHubspotAnalytics: true,
}
