export default {
  apiUrl: 'https://staging-api.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.staging.crystalknows.com',
  appUrl: 'https://staging.crystalknows.com',
  devMode: false,
  hubURL: 'https://staging-hub.crystalknows.com',
  marketingUrl: 'https://staging.crystalknows.com',
  pk_live: 'pk_test_2DwpK6wrrckZ87ZjEfMsyabr',
  stripe_price_id: 'price_0QHRcpnIS1nxHN4V02H5VmgI',
  rudderStackWriteKey: '1kU6UMcvdKczMrftJNVU8VvT4oZ',
  useDevtoolsExtension: true,
  useFullStory: false,
  useHubspotAnalytics: false,
  authTokenName: 'CRYSTAL_STAGING_AUTH',
  sessionTokenName: 'CRYSTAL_STAGING_SESS',
  pageTokenName: 'CRYSTAL_STAGING_PAGE',
}
